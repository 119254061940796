













































import Vue from 'vue';
import CountryFlag from 'vue-country-flag';
import AppSettings from '@/common/utils/preferences';

export default Vue.extend({
  name: 'LanguageSelector',

  components: {
    CountryFlag,
  },

  data: () => ({
    languages: [
      { language: 'SUOMI', icon: 'fi', locale: 'fi' },
      { language: 'ENGLISH', icon: 'gb', locale: 'en' },
    ],
  }),
  computed: {
    activeLanguageIcon(): string | undefined {
      return this.languages.find((l) => l.locale === this.$root.$i18n.locale)?.icon;
    },
  },
  methods: {
    selectLanguage(locale: string) {
      this.$emit('select', locale);
      this.$root.$i18n.locale = locale;

      // Save selected language to the local storage
      AppSettings.language = locale;
    },
  },
});
