



















































































































































































































import Vue from 'vue';
import LanguageSelector from '@/common/components/LanguageSelector.vue';
import AppSettings from '@/common/utils/preferences';
import { Page } from '@/common/api/matrix';

export default Vue.extend({
  name: 'App',
  components: { LanguageSelector },
  props: {
    headerImg: {
      type: Function,
      required: true,
    },
    logoImg: {
      type: Function,
      required: true,
    },
    pImg: {
      type: Function,
      required: true,
    },
    euImg: {
      type: Function,
      required: true,
    },
    vipuImg: {
      type: Function,
      required: true,
    },
    eu: {
      type: Boolean,
      required: true,
    },
    mapImg: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    warningTime: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    appBarHeight: '75px',
    parkIconHeight: '45px',
    drawerOpen: false,
    langDrawerOpen: false,
    copyrightYear: process.env.BUILD_YEAR,
  }),

  computed: {
    darkMode: {
      get(): boolean { return this.$vuetify.theme.dark; },
      set(value: boolean) { this.$vuetify.theme.dark = value; },
    },
    themeToggleIcon() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.darkMode ? 'mdi-white-balance-sunny' : 'mdi-weather-night';
    },
    themeToggleText() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$t(this.darkMode ? 'header-lightMode' : 'header-darkMode');
    },
    menuLinks() {
      const links = [];

      if (this.$authenticated) {
        links.push(
          {
            order: 0, icon: 'mdi-parking', tag: 'global-sideMenuToParking', route: 'Welcome', id: 'menuParking',
          },
          {
            order: 3, icon: 'mdi-history', tag: 'global-viewHistory', route: 'History', id: 'menuHistory',
          },
          {
            order: 4, icon: 'mdi-account-details', tag: 'global-myDetails', route: 'Account', id: 'menuDetails',
          },
        );
      }

      links.push(
        {
          order: 1, icon: 'mdi-help-circle', tag: 'header-instructions', route: 'Instructions', id: 'menuInstructions',
        },
        {
          order: 2, icon: 'mdi-book-open-page-variant', tag: 'global-tos', route: 'ToS', id: 'menuTos',
        },
        {
          order: 5, icon: 'mdi-shield-account', tag: 'global-privacy', route: 'Privacy', id: 'menuPrivacy',
        },
        /* {
          order: 6, icon: 'mdi-lifebuoy', tag: 'footer-accessibility', route: 'Accessibility', id: 'menuAccessibility',
        }, */
      );

      return links.sort((a, b) => 0 - (a.order > b.order ? -1 : 1));
    },
  },

  watch: {
    drawerOpen() {
      this.langDrawerOpen = false;
    },
    // Save selected theme to the local storage when changed
    // eslint-disable-next-line func-names
    '$vuetify.theme.dark': function (value) {
      AppSettings.useDarkTheme = value;
    },
  },

  created() {
    // Set page title
    document.title = this.title;

    // Load theme settings
    this.$vuetify.theme.dark = AppSettings.useDarkTheme;
  },

  methods: {
    async sendLogOut(): Promise<void> {
      this.$logOut().then(() => this.$changeRoute('Home'));
    },
    changeRoute(name: string) {
      this.$changeRoute(name as Page);
    },
  },
});
