





















































import Vue from 'vue';

export default Vue.extend({
  props: {
    x: {
      type: String,
      default: 'right',
    },
    y: {
      type: String,
      default: 'bottom',
    },
    app: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'info',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    classes: {
      type: [String, Object, Array],
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: '',
    },
    closeIcon: {
      type: String,
      default: 'close',
    },
    closeColor: {
      type: String,
      default: '',
    },
  },
  data: (): Record<string, unknown> => ({
    active: false,
  }),
  watch: {
    active(isActive: boolean, wasActive: boolean): void {
      this.$emit('statusChange', isActive, wasActive);
    },
  },
  mounted(): void {
    this.$nextTick(() => this.show());
  },
  methods: {
    show(): void {
      this.active = true;
    },
    close(): void {
      this.active = false;
    },
    dismiss(): void {
      if (this.dismissable) {
        this.close();
      }
    },
  },
});
